import React from "react";
import CompanyLogo from "../assets/images/gaig-logo.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";

const Header = () => {
    return (
        <div className="ga_login-app-header-container">
            <div className="ga_login-app-header-container-image">
                <img style={{objectFit: "contain"}} src={CompanyLogo} alt="logo"/>
            </div>
            <div className="ga_login-app-header-container-support">
                <div className="ga_login-app-header-container-support-info">
                    <p>Need assistance?</p>
                </div>
                <div className="ga_login-app-header-container-support-info">
                    <FontAwesomeIcon icon={faPhone} className="ga_login-app-header-container-support-info-callIcon"/>
                    <p>800-543-1150</p>
                </div>
            </div>
        </div>
    )
}

export default Header;