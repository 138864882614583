import {faInfoCircle, faPen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import WizardPanel from "../components/WizardPanel.js";
import {isFeatureEnabled} from "../config/FeatureFlagConfig";
import {isUserInAIE, requestAccess, validateUsername} from "../service/UserService";
import {Icon, Icons} from '../icons.jsx';

import '../assets/sass/components/buttons.scss';
import '../assets/sass/pages/InsuredRegistration.scss';
import '../assets/sass/pages/CreatePasswordPage.scss';
import {primaryShades} from "../util/GaigColors";

/** @enum */
const usernameStates = Object.freeze({
    INVALID_EMAIL: Symbol('INVALID_EMAIL'),
    USER_ALREADY_EXISTS: Symbol('USER_ALREADY_EXISTS'),
    UNVERIFIED: Symbol('UNVERIFIED'),
    VALID: Symbol('VALID'),
    GENERIC_ERROR: Symbol('GENERIC_ERROR')
});

const states = [
    "AK", "AL", "AR", "AS", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "GU", "HI", "IA", "ID", "IL", "IN", "KS",
    "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MP", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY",
    "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UM", "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY"
]

const InsuredRegistration = () => {
    const [failedSubmit, setFailedSubmit] = useState(false)
    const [usernameState, setUsernameState] = useState(usernameStates.UNVERIFIED)
    const [registrationNumber, setRegistrationNumber] = useState('')
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        userTitle: '',
        phone: '',
        email: '',
        customerNumbers: [],
        policies: []
    });
    const [policyInfo, setPolicyInfo] = useState({
        policyNumber: '',
        postalCode: '',
        customerNumber: '',
        businessUnitCode: '',
        policySymbol: ''
    });

    // Data to display primary office flow
    const [showAddressField, setShowAddressField] = useState(false)
    const [showAddress, setShowAddress] = useState(false)
    const [addressDetails] = useState({
        street: '',
        city: '',
        state: '',
        zip: ''
    });
    const [tempAddressDetails, setTempAddressDetails] = useState({...addressDetails});
    // the confirm email address modal isn't actually a wizard step
    const [submittedRequest, setSubmittedRequest] = useState(false);
    if(!isFeatureEnabled('selfRegistration')) {
        return <></>
    }
    const policyDetails = {
        title: "policy details",
        header: "Policy Details",
        subheader: "Please add your policy number and policy zip code.",
        body: (
            <form className={failedSubmit ? 'error-form' : ''} onSubmit={event => event.preventDefault()}>
                <div>
                    <label className="form-label">POLICY NUMBER <b className="required">*</b></label>
                    <div className="input-group">
                        <span className="input-group-text">
                            <Icon name={Icons.fileBlankOutline} color={primaryShades.navy7} height={22} width={22} />
                        </span>
                        <input type="text"
                               className="form-control"
                               id="policyNumber"
                               required
                               placeholder="1234567"
                               maxLength={7}
                               minLength={7}
                               onChange={(event) => {
                            setPolicyInfo({...policyInfo, policyNumber: event.target.value});
                        }
                        }/>
                    </div>
                    <div id="policyNumberHelp" className="form-text form-tip">
                        <Icon name={Icons.infoCircleOutline} width={16} height={16} />
                        This is not your billing Account Number. 7 characters only.
                    </div>
                </div>
                <br/>
                <div>
                    <label className="form-label">POLICY ZIP CODE <b className="required">*</b></label>
                    <div className="input-group">
                        <span className="input-group-text">
                            <Icon name={Icons.locationOutline} color={primaryShades.navy7} width={22} height={22} />
                        </span>
                        <input type="number" className="form-control" id="zipCode" required placeholder="55555" onChange={(event) => {
                            setPolicyInfo({...policyInfo, postalCode: event.target.value})
                        }} />
                    </div>
                    <div className="form-text form-tip">
                        <Icon name={Icons.infoCircleOutline} width={16} height={16} />
                        Zip Code from policy documents.
                    </div>
                </div>
                {failedSubmit ? (
                    <div id='invalidPolicyCombo' className="row">
                        <div className="col-10 offset-1">
                            The policy number and/or zip code don’t match in our system. Please try again.
                        </div>
                    </div>
                ) : null}
            </form>
        ),
        submit: async () => {
            return await fetch(`/api/policies?filters.policyNumber=${policyInfo.policyNumber}&filters.insuredPostalCode=${policyInfo.postalCode}`)
                .then(res => res.json())
                .then(res => {
                    setPolicyInfo({...policyInfo, customerNumber: res.customerNumber})
                    setPolicyInfo({...policyInfo, businessUnitCode: res.businessUnitCode})
                    const registrationNumber = res.businessUnitCode === 'STRCMP' ? res.policyNumber : res.policySymbol + ' ' + res.policyNumber;
                    setRegistrationNumber(registrationNumber)
                    return res
                })
                .catch((error) => {
                    setFailedSubmit(true);
                    return false
                });
        },
        isValid: () => {
            // TODO we can't register Canadian Zip Codes (6 chars, yes they exist in prod) with this
            return policyInfo.policyNumber?.length === 7 && policyInfo.postalCode?.length === 5
        }
    }

    function isValidAddress() {
        return ((Object.entries(tempAddressDetails)
            .filter(([k, v]) => {
                return (!v)
            }).length) || (
                (tempAddressDetails.city === addressDetails.city) &&
                (tempAddressDetails.street === addressDetails.street) &&
                (tempAddressDetails.state === addressDetails.state) &&
                (tempAddressDetails.zip === addressDetails.zip)
            )
        );
    }

    function save() {
        addressDetails.city = tempAddressDetails.city;
        addressDetails.street = tempAddressDetails.street;
        addressDetails.state = tempAddressDetails.state;
        addressDetails.zip = tempAddressDetails.zip;
        setShowAddressField(false);
        setShowAddress(true);
    }

    function cancel() {
        tempAddressDetails.city = addressDetails.city;
        tempAddressDetails.street = addressDetails.street;
        tempAddressDetails.state = addressDetails.state;
        tempAddressDetails.zip = addressDetails.zip;
        setShowAddressField(false);
    }

    const emailTyped = async (email) => {
        setUserInfo({...userInfo, email});
        validateUsername(email).then((resp) => {
            if (resp === true) {
                setUsernameState(usernameStates.UNVERIFIED)
            } else {
                setUsernameState(usernameStates.INVALID_EMAIL)
            }
        })
    }

    const checkUsername = async (email) => {
        if (usernameState === usernameStates.INVALID_EMAIL || email?.trim() === '') {
            return;
        }
        try {
            const userAlreadyExists = await isUserInAIE(email);
            setUsernameState(userAlreadyExists ? usernameStates.USER_ALREADY_EXISTS : usernameStates.VALID);
        } catch (e) {
            setUsernameState(usernameStates.GENERIC_ERROR);
        }
    }

    const usernameError = () => {
        if (usernameState === usernameStates.INVALID_EMAIL) {
            return <div id="policyNumberHelp" className="form-text form-tip error-text">
                <FontAwesomeIcon icon={faInfoCircle}/>
                Email not formatted correctly.
            </div>;
        } else if (usernameState === usernameStates.USER_ALREADY_EXISTS) {
            return <div id="policyNumberHelp" className="form-text form-tip error-text">
                <FontAwesomeIcon icon={faInfoCircle}/>
                This email is already in use. Please choose a different email.
            </div>;
        } else if(usernameState === usernameStates.GENERIC_ERROR) {
            return <div id="policyNumberHelp" className="form-text form-tip error-text">
                <FontAwesomeIcon icon={faInfoCircle}/>
                There was an error verifying your username. Please contact customer care at <a className={'telephone'} href={'tel:+18005454269'}>800-545-4269</a>.
            </div>
        }
    };

    const handlePhoneInput = (target: HTMLInputElement) => {
        let val = target.value.replaceAll(/[^0-9]/g, '');
        if (val.length >= 10) {
            val = val.substring(0, 10);
            const areaCode = val.substring(0, 3);
            const part1 = val.substring(3, 6);
            const part2 = val.substring(6);
            val = `(${areaCode}) ${part1}-${part2}`;
        }
        setUserInfo({...userInfo, phone: val})
    }

    const userDetails = {
        title: "Account Information",
        header: "Account Information",
        subheader: (
          policyInfo.businessUnitCode === 'STRCMP'
          ? <span className="access-info"> In order to grant you access, we will need some basic information. If you need assistance regarding this process, please email us at <a className="email-link" href="mailto:ReportRequest@StrategicComp.com" target="_blank" rel="noreferrer" >reportrequest@strategiccomp.com</a>.</span>
            : <span className="access-info">In order to grant you access, we will need some basic information. If you need assistance regarding this process, please email us at <a className="email-link" href="mailto:customercare@gaig.com">customercare@gaig.com.</a></span>
        ),
        body: (
            <form className="row">
                <div className="col-12">
                    <label className="form-label">EMAIL <b className="required">*</b></label>
                    <input onChange={e => emailTyped(e.target.value)} onBlur={e => checkUsername(e.target.value)} type="email" className={([usernameStates.INVALID_EMAIL, usernameStates.USER_ALREADY_EXISTS].includes(usernameState)) ? "form-control error" : "form-control"} id="email" required placeholder="example@email.com"/>
                </div>
                {
                   usernameError()
                }

                <div className="col-6">
                    <label className="form-label account-info-label">FIRST NAME <b className="required">*</b></label>
                    <input type="text" className="form-control" id="firstName" required placeholder="First name" onChange={(event) => {setUserInfo({...userInfo, firstName: event.target.value})}}/>
                </div>
                <div className="col-6">
                    <label className="form-label account-info-label">LAST NAME <b className="required">*</b></label>
                    <input type="text" className="form-control" id="lastName" required placeholder="Last name" onChange={(event) => {setUserInfo({...userInfo, lastName: event.target.value})}} />
                </div>
                <div className="col-6">
                    <label className="form-label account-info-label">TITLE</label>
                    <input type="text" className="form-control" id="userTitle" placeholder="Your title" onChange={(event) => {setUserInfo({...userInfo, userTitle: event.target.value})}}/>
                </div>
                <div className="col-6">
                    <label className="form-label account-info-label">PHONE</label>
                    <input type="tel" className="form-control" id="phone" placeholder="(555) 555-5555" value={userInfo.phone} onChange={e => handlePhoneInput(e.target)}/>
                </div>

                {/*<div> TODO Uncomment when primary office is back in scope*/}
                {/*    <label className="form-label" style={{paddingTop: "16px"}}>PRIMARY OFFICE </label>*/}
                {/*</div>*/}

                {showAddressField ? (
                    <div>
                        <div style={{backgroundColor: "#F2F4F6", borderLeft: "2px solid #99A9B8", padding:"8px"}}>
                            <div className={"row no-gutters"}>
                                <span className={"col-sm-auto primary-office-text"}>Primary Office Address</span>
                                <span className={"col"}></span>
                                <button className={"col-2 cancel-btn"} onClick={() => {cancel()}}>Cancel</button>
                                <button className={"col-2 ga_Button ga_Button-primary"} disabled={isValidAddress()} onClick={() => {save()}}>Save</button>
                            </div>
                            <div className={"row"}>
                                <div>
                                    <label className="form-label">STREET</label>
                                    <input type="text" className="form-control" id="street" placeholder="123 Main St." value={tempAddressDetails.street} onChange={(event) =>  {setTempAddressDetails({...tempAddressDetails, street: event.target.value})}}/>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-4"}>
                                    <label className="form-label account-info-label">CITY</label>
                                    <input type="text" className="form-control" id="city" placeholder="City" value={tempAddressDetails.city} onChange={(event) =>  {setTempAddressDetails({...tempAddressDetails, city: event.target.value})}}/>
                                </div>
                                <div className={"col-5"}>
                                    <label className="form-label account-info-label">STATE</label>
                                    <select required className={"form-select"} value={tempAddressDetails.state} onChange={(event) =>  {setTempAddressDetails({...tempAddressDetails, state: event.target.value})}}>
                                        <option hidden disabled value={""}>Select state</option>
                                        {states.map((state) => (
                                            <option value={state} key={state}>{state}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className={"col-3"}>
                                    <label className="form-label account-info-label">ZIP CODE</label>
                                    <input type="number" className="form-control" id="zipCode" placeholder="12345" value={tempAddressDetails.zip} onChange={(event) =>  {setTempAddressDetails({...tempAddressDetails, zip: event.target.value})}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div>
                            {showAddress ?
                                <div>
                                    <div className={"address-text"} style={{paddingTop:"4px", paddingBottom:"4px"}}>
                                        {addressDetails.street + ", " + addressDetails.city + " " + addressDetails.state + ", " + addressDetails.zip}
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faPen} className={"primary-office"} /> 
                                        <span className="primary-office-btn primary-office" onClick={() => {setShowAddressField(true)}}>Edit primary address</span>
                                    </div>
                                </div>
                                : null
                                // <div>  TODO: Uncomment and remove null when primary office is back in scope
                                //     <FontAwesomeIcon icon={faPlus} className={"primary-office"}/> 
                                //     <span className="primary-office-btn primary-office" onClick={() => {setShowAddressField(true)}}>Add primary address</span>
                                // </div>
                            }
                        </div>
                    </div>
                )
                }
            </form>
        ),
        submit: async () => {
            try {
                await requestAccess({
                    userName: userInfo.email,
                    registrationNumber,
                    businessUnitCode: policyInfo.businessUnitCode,
                    addressDetails, ...userInfo,
                    selfRegistrationPolicyNumber: policyInfo.policyNumber
                });
                setSubmittedRequest(true);
                return true;
            } catch (e) {
                console.error(e);
                setFailedSubmit(true);
                return false;
            }
        },
        isValid: () => {
            return !Object.entries(userInfo)
                .filter(([k, v]) => {
                    return (k !== "phone" && k !== "userTitle" && !v)
                }).length && usernameState === usernameStates.VALID
        }
    }

    const footer = (
      <div id={'footerButtonWrapper'} className="button-wrapper">
          <Link id={'footerButton'} className="ga_Button ga_Button-primary-outline" to='/insured'>
              Already have an account? Log in
          </Link>
      </div>
    )

    return (
        <div id="insuredRegistrationPage" className="container-fluid">
            {submittedRequest ?
              <div className="row">
                  <div className="col-lg-4 col-md-1"></div>
                  <div className="col-lg-4 col-md-10">
                      <div className="box-shadow gaig-panel">
                          <h1 className="title">Confirm your email address</h1>
                          <p>A verification link has been sent to <span className="value">{userInfo.email}</span>. Please check your inbox and click on the link to confirm your email address</p>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-1"></div>
              </div>
              : <WizardPanel steps={[policyDetails, userDetails]} footer={footer}/>}
        </div>
    );
};

export default InsuredRegistration;
